import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FaEllipsisH, FaEllipsisV } from 'react-icons/fa';
import { useClickAway } from 'react-use';
import RoundButtonBase from '../RoundButtonBase';
import styled from '@emotion/styled';
import ability from 'components/context/ability';
import { ABILITY_TYPES, TITLES, URLS } from '../constants';

const StyledCardItemActionMenu = styled.div`
  // margin-right: 4px;
  border-radius: 4px;
  padding: 8px 10px;
  // border: ;
  background: #fff;
  font-weight: 500;

  border: ${(props) => (props.isOpen ? '1px solid #3fb0fa' : '1px solid #666')};
  div > svg {
    font-size: 34px;
    color: #af97d8;
  }
  &:hover {
    // border: #b2d897 solid 3px;
    cursor: pointer;
    // background: #d6d6d6;

    div > svg {
      color: #b2d897;
    }
  }
`;

const StyledUl = styled.ul`
  display: none;
`;

const StyledDivDropDown = styled.div`
  ul {
    display: block;
    width: 100%;
    // right: 0;
  }
`;

const StyledTitle = styled.div`
  text-transform: none;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: rgb(18, 28, 45);
  cursor: pointer;
`;
const SimpleDropDown = ({
  options,
  handleOnChange,
  initialTextValue,
  title = 'Select',
}) => {
  const ref = useRef(null);
  useClickAway(ref, () => {
    console.log('OUTSIDE CLICKED');
    setIsOpen(false);
  });
  const [isOpen, setIsOpen] = useState(false);
  const [textValue, setTextValue] = useState(initialTextValue);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const hideDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (initialTextValue) {
      setTextValue(initialTextValue);
      handleOnChange(initialTextValue);
    }
  }, [initialTextValue]);
  const canRotaPadUserUpdate = useMemo(
    () =>
      ability &&
      ability.can(ABILITY_TYPES.read, TITLES[`${URLS.dashboard}`]) &&
      ability.can(ABILITY_TYPES.update, TITLES[`${URLS.dashboard}`]),
    [ability]
  );

  const handleChange = useCallback(
    (item) => {
      handleOnChange(item);
      setTextValue(item);
      setIsOpen(false);
    },
    [handleOnChange, setTextValue, setIsOpen]
  );
  return (
    <StyledDivDropDown
      className='dropdown'
      onBlur={hideDropdown}
      ref={ref}
    >
      <StyledTitle>{title}</StyledTitle>
      <StyledCardItemActionMenu
        onClick={toggleDropdown}
        isOpen={isOpen}
      >
        {textValue}
      </StyledCardItemActionMenu>
      {isOpen && (
        <StyledUl className='dropdown-menu'>
          {options.map(({ name, type, disabled, onClick }) => {
            return (
              <li
                key={name}
                onClick={() => handleChange(name)}
                {...(type === 'header'
                  ? {}
                  : { onClick: () => handleChange(name) })}
              >
                {type === 'header' ? (
                  <h6 className='dropdown-header'>{name}</h6>
                ) : (
                  <a
                    className={
                      disabled ? 'dropdown-item disabled' : 'dropdown-item'
                    }
                    href='#'
                  >
                    {name}
                  </a>
                )}
              </li>
            );
          })}
        </StyledUl>
      )}
    </StyledDivDropDown>
  );
};

export default SimpleDropDown;
