import React, { useMemo, useRef, useState } from 'react';
import { FaEllipsisH, FaEllipsisV } from 'react-icons/fa';
import { useClickAway } from 'react-use';
import RoundButtonBase from '../RoundButtonBase';
import styled from '@emotion/styled';
import ability from 'components/context/ability';
import { ABILITY_TYPES, TITLES, URLS } from '../constants';

const StyledCardItemActionMenu = styled.div`
  nargin-right: 4px;
  border-radius: 20px;
  padding: 8px 10px;
  div > svg {
    font-size: 34px;
    color: #af97d8;
  }
  &:hover {
    // border: #b2d897 solid 3px;
    cursor: pointer;
    background: #d6d6d6;

    div > svg {
      color: #b2d897;
    }
  }
`;

const Dropdown = ({
  setVisibleCAF,
  setVisibleTEF,
  isAssigned,
  isApproved,
  handleRemoveAttendee,
  handleAddAttendee,
  canSendApprovalRequest,
  handleOpenShift,
  setVisibleLeft,
  isUser,
}) => {
  const ref = useRef(null);
  useClickAway(ref, () => {
    console.log('OUTSIDE CLICKED');
    setIsOpen(false);
  });
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const hideDropdown = () => {
    setIsOpen(false);
  };

  const canRotaPadUserUpdate = useMemo(
    () =>
      ability &&
      ability.can(ABILITY_TYPES.read, TITLES[`${URLS.dashboard}`]) &&
      ability.can(ABILITY_TYPES.update, TITLES[`${URLS.dashboard}`]),
    [ability]
  );

  return (
    <div
      className='dropdown'
      onBlur={hideDropdown}
    >
      <StyledCardItemActionMenu onClick={toggleDropdown}>
        <FaEllipsisV className='dropdown-btn'></FaEllipsisV>
      </StyledCardItemActionMenu>

      {isOpen && (
        <ul
          className='dropdown-menu dropdown-content'
          ref={ref}
        >
          <li
            onClick={() => {
              handleOpenShift();
              setIsOpen(false);
            }}
          >
            <a
              className='dropdown-item'
              href='#'
            >
              View
            </a>
          </li>
          <li
            onClick={() => {
              setVisibleLeft(false);
              setVisibleTEF(true);
            }}
          >
            <a
              className={
                (isUser && isAssigned) || canRotaPadUserUpdate
                  ? 'dropdown-item'
                  : 'dropdown-item disabled'
              }
              href='#'
            >
              Time entry
            </a>
          </li>

          <li
            onClick={() => {
              setVisibleLeft(false);
              setVisibleCAF(true);
            }}
          >
            <a
              className={
                (isUser || canRotaPadUserUpdate) && canSendApprovalRequest
                  ? 'dropdown-item'
                  : 'dropdown-item disabled'
              }
              href='#'
            >
              Send approval
            </a>
          </li>

          <li>
            <hr className='dropdown-divider' />
          </li>
          <li
            onClick={() => {
              if (isAssigned) {
                handleRemoveAttendee();
              } else {
                handleAddAttendee();
              }

              setVisibleLeft(false);
            }}
          >
            <a
              className={
                isUser || !isAssigned || canRotaPadUserUpdate
                  ? 'dropdown-item'
                  : 'dropdown-item disabled'
              }
              href='#'
            >
              {isAssigned ? 'Unassign shift' : 'Assign shift'}
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
