import { ToolTip } from 'components/common';
import { RoundedPill } from '..';
import { FaRegCommentDots } from 'react-icons/fa';
import styled from '@emotion/styled';
import { useState } from 'react';

const StyledDiv = styled.div`
  position: absolute;
  bottom: 18px;
  left: 18px;
`;
const CustomerComment = ({ attendee, customer }) => {
  // console.log('customer ==== ', customer);

  const [showComment, setShowComment] = useState(false);
  return (
    <>
      <ToolTip
        placement='top'
        overlay={
          <span style={{ fontSize: 10 }}>
            {attendee.customerStaffEmail && attendee.customerStaffName && (
              <>
                Customer staff: <strong>{attendee.customerStaffName}</strong>
                <br />
              </>
            )}
            Comment: <strong>{attendee.customerComment}</strong>
            <br />
          </span>
        }
      >
        <RoundedPill
          className='rounded-pill mt-1 mb-2 ms-1'
          role='group'
          aria-label='Basic outlined example'
          active={true}
          onMouseOver={() => setShowComment(true)}
          onMouseOut={() => setShowComment(false)}
          style={{ cursor: 'pointer' }}
          // key={attendee.uid}
        >
          <FaRegCommentDots style={{ fontSize: '18px' }} />
        </RoundedPill>
      </ToolTip>
      {/* {showComment && (
        <StyledDiv>
          Customer comment: <strong>{attendee.customerComment}</strong>
        </StyledDiv>
      )} */}
    </>
  );
};

export default CustomerComment;
