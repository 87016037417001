import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import styled from '@emotion/styled';
import { ToolTip } from '../../../common';
import TimeEntry from '../TimeEntry';
import { FiAlertTriangle } from 'react-icons/fi';

const RoundedCircle = styled.div`
  padding: 4px;
  background: #0000004f;
  line-height: 7px;
  cursor: pointer;
  &:hover {
    background: #00000098;
  }
`;

const TimeCheckStyledDiv = styled.span`
  background: #00000036;
  border-radius: 5px;
  padding: 2px;
  margin-left: 8px;
  font-size: 11px;
  letter-spacing: 0.04rem;
  cursor: ${({ active }) =>
    active !== undefined && active ? 'pointer' : 'default'};
  &:hover {
    background: #000000a1;
    color: #fff;
  }
`;

const TimeEntryContainer = ({
  isRotaPadUserAdmin,
  attendee,
  rotaPadUser,
  start,
  uid,
  title,
  handleAddAttendeeHours,
  visible,
  setVisible,
}) => {
  // const [visible, setVisible] = useState(false);

  // console.log('attendee ===== ', attendee);
  return (
    <div>
      <ToolTip
        {...(!isRotaPadUserAdmin && attendee.email === rotaPadUser.email
          ? { placement: 'top' }
          : { visible: false })}
        // placement='top'
        overlay={<span style={{ fontSize: 10 }}>Click to update</span>}
      >
        <TimeCheckStyledDiv
          {...(!isRotaPadUserAdmin && attendee.email === rotaPadUser.email
            ? { onClick: () => setVisible(true) }
            : {})}
          active={!isRotaPadUserAdmin && attendee.email === rotaPadUser.email}
          // onClick={() => setVisible(true)}
        >
          {attendee?.timeEntry && attendee?.timeEntry !== 0
            ? attendee?.timeEntry.toFixed(2)
            : `0:00`}
          hrs
        </TimeCheckStyledDiv>
      </ToolTip>

      <TimeEntry
        hours={attendee?.timeEntry}
        sleepEntry={attendee?.sleepEntry}
        startTimeEntry={attendee?.startTimeEntry}
        endTimeEntry={attendee?.endTimeEntry}
        visible={visible}
        setVisible={setVisible}
        start={start}
        uid={uid}
        title={title}
        handleAddAttendeeHours={handleAddAttendeeHours}
      />
    </div>
  );
};

export default TimeEntryContainer;
