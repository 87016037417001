import styled from '@emotion/styled';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { TOAST_TYPE } from '../../common/constants';
import { useToast } from '../../common/hooks/useToast';
import { useApiService } from '../../context';
import { StyledInputTextArea } from '../../workspace/AddWorkSpaceForm/styles';
import { generate as generateUUID } from 'short-uuid';
import UpdateLogo from './UpdateLogo';
import { ButtonBase } from 'components/common';

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
  margin-top: 8px;
`;

const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
`;

const AccountSettings = ({ rotaPadUser }) => {
  const {
    user,
    createDoc,
    database,
    setRotaPadUser,
    rotaPadAccountSettingsTopic,
  } = useApiService();

  const { triggerToast } = useToast();

  const validate = (data) => {
    let errors = {};

    if (!data.name) {
      errors.name = 'Company Name is required.';
    }

    return errors;
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
    );
  };
  const handleSubmitUserDetails = useCallback(
    async (data, form) => {
      // console.log("rotaPadUser ====== ", rotaPadUser);
      const { uid } = data;
      if (rotaPadUser) {
        const success = await createDoc(
          database,
          `settings/profile`,
          {
            ...(uid ? data : { ...data, uid: generateUUID() }),
          }
          // setRotaPadUser
        );

        if (success) {
          triggerToast({
            message: 'Profile successfully updated!',
            type: TOAST_TYPE.success,
          });
        } else {
          triggerToast({
            message: 'Profile could not be updated!',
            type: TOAST_TYPE.warning,
          });
        }
      }
    },
    [createDoc, database, rotaPadUser, setRotaPadUser, triggerToast, user]
  );
  console.log('rotaPadAccountSettingsTopic ==== ', rotaPadAccountSettingsTopic);

  const initValues = rotaPadAccountSettingsTopic[`profile`] || {
    email: '',
    name: '',
    address: '',
    phone: '',
    website: '',
    bank: '',
    uid: generateUUID(),
  };
  return (
    <div>
      <Form
        onSubmit={handleSubmitUserDetails}
        initialValues={initValues}
        validate={validate}
        render={({
          handleSubmit,
          pristine,
          submitting,
          hasValidationErrors,
        }) => (
          <form
            onSubmit={handleSubmit}
            className='p-fluid'
          >
            <StyledH5 className='mb-3'>Update Account Settings</StyledH5>
            <UpdateLogo uid={initValues.uid} />
            <div className='row g-3'>
              <div className='col-xs-12 col-md-8 mb-2'>
                <Field
                  name='name'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel
                          htmlFor='name'
                          className={classNames({
                            'p-error': isFormFieldValid(meta),
                          })}
                        >
                          Company Name <span className='text-danger'>*</span>
                        </StyledLabel>
                        <StyledInput
                          id='name'
                          {...input}
                          className={classNames({
                            'p-invalid': isFormFieldValid(meta),
                          })}
                        />
                      </div>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Field
                  name='address'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel htmlFor='description'>Address</StyledLabel>
                        <StyledInputTextArea
                          id='address'
                          placeholder='Enter company address'
                          rows={5}
                          cols={30}
                          {...input}
                          className='w-100 p-inputtext-sm'
                          // disabled={!isUserAdmin}
                        />
                      </div>
                    </div>
                  )}
                />

                <Field
                  name='email'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel
                          htmlFor='email'
                          className={classNames({
                            'p-error': isFormFieldValid(meta),
                          })}
                        >
                          Email
                        </StyledLabel>

                        <StyledInput
                          id='email'
                          {...input}
                          className={classNames({
                            'p-invalid': isFormFieldValid(meta),
                          })}
                          // disabled
                        />
                      </div>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />

                <Field
                  name='phone'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel
                          htmlFor='phone'
                          className={classNames({
                            'p-error': isFormFieldValid(meta),
                          })}
                        >
                          Phone
                        </StyledLabel>

                        <StyledInput
                          id='phone'
                          {...input}
                          className={classNames({
                            'p-invalid': isFormFieldValid(meta),
                          })}
                          // disabled
                        />
                      </div>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />

                <Field
                  name='website'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel
                          htmlFor='website'
                          className={classNames({
                            'p-error': isFormFieldValid(meta),
                          })}
                        >
                          Website
                        </StyledLabel>

                        <StyledInput
                          id='website'
                          {...input}
                          className={classNames({
                            'p-invalid': isFormFieldValid(meta),
                          })}
                          // disabled
                        />
                      </div>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
              </div>
            </div>

            <StyledH5 className='mt-4 mb-3'>Banking Information</StyledH5>
            <div className='row g-3'>
              <div className='col-xs-12 col-md-8 mb-2'>
                <Field
                  name='bank'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel htmlFor='description'>
                          Bank Account Info
                        </StyledLabel>
                        <StyledInputTextArea
                          id='bank'
                          placeholder='Enter bank information'
                          rows={5}
                          cols={30}
                          {...input}
                          className='w-100 p-inputtext-sm'
                          // disabled={!isUserAdmin}
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>

            <div className='row g-3 mt-2'>
              <div className='col-xs-12 col-md-8'>
                <ButtonBase
                  type='submit'
                  label='Update Account Settings'
                  variant='primary'
                  size='large'
                  disabled={pristine || submitting || hasValidationErrors}
                >
                  Update Account Settings
                </ButtonBase>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default AccountSettings;
