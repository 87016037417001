// Login component

import styled from '@emotion/styled';
import {
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  getAuth,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { ref, set } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonBase, ToolTip } from '../common';
import { TOAST_TYPE } from '../common/constants';
import { useBranding } from '../common/hooks/useBranding';
import { useToast } from '../common/hooks/useToast';
import Logo from '../common/img/logo.png';
import { FiInfo } from 'react-icons/fi';
import { Helmet } from 'react-helmet';
import { StyledLinkDiv } from 'components/login';
// import './index.scss';

const StyledDiv = styled.div`
  cursor: pointer;
  z-index: 200;
  position: absolute;
  right: 15px;
  font-size: large;
  color: #6c757d;
  top: 16px;

  &:hover {
    color: #7f00ff;
  }
`;

const ErrorStyledDiv = styled.div`
  background-color: #fff;
  padding: 24px;
`;

const StyledInput = styled.input`
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

function writeUserData(db, userId, name, email, imageUrl) {
  // const db = getDatabase();
  // set(ref(db, "users/" + userId), {
  // 	username: name,
  // 	email: email,
  // 	profile_picture: imageUrl,
  // });

  set(ref(db, 'rotas/2023/workorders/555'), {
    title: '555',
  });
}
const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    hide: true,
    info: 'Show password',
  });
  const [showMessage, setShowMessage] = useState(false);
  const [showErrMessage, setShowErrMessage] = useState(false);

  const auth = getAuth();

  const navigate = useNavigate();
  let { pathname } = useLocation();
  const { triggerToast } = useToast();
  const { logo, loginParagraph, footerTag } = useBranding();

  // Remove trailing route string and redirect to '/'
  // useEffect(() => {
  //   if (navigate || pathname) {
  //     navigate('/');
  //   }
  // }, [navigate, pathname]);

  const handleResetPassword = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        // console.log('email ===== ', email);
        setIsLoginLoading(true);
        await sendPasswordResetEmail(auth, email);

        // console.log(send);
        // triggerToast({
        //   message: 'Reset password email successfully sent to ' + email,
        //   type: TOAST_TYPE.success,
        // });

        setIsLoginLoading(false);
        setShowMessage(true);
        setShowErrMessage(false);
      } catch (error) {
        // console.log(error.message.replace('Firebase: ', ''));
        setIsLoginLoading(false);
        // triggerToast({
        //   message: error.message.replace('Firebase: ', ''),
        //   type: TOAST_TYPE.warning,
        // });
        setShowErrMessage(true);
        setShowMessage(false);
      }
    },
    [auth, email, triggerToast]
  );

  return (
    <>
      <Helmet>
        <title>{`Forgot Password | RotaPad`}</title>
      </Helmet>
      <div className={`formBody text-center `}>
        <ErrorStyledDiv className='shadow-sm form-signin w-100 m-auto'>
          <form noValidate>
            {logo?.fullLogo ? (
              <>
                <img
                  src={logo?.fullLogo}
                  alt='Logo'
                  className='mb-2'
                />
                <br />
                <img
                  src={Logo}
                  width='100'
                  height='21'
                  className='mb-4'
                  alt='ROTA PAD logo'
                />
              </>
            ) : (
              <img
                src={Logo}
                width='140'
                height='32'
                className='mb-4'
                alt='ROTA PAD logo'
              />
            )}

            {showErrMessage && (
              <div className='border-top border-bottom py-4 mb-4'>
                <div className='form-floating'>
                  <FiInfo style={{ fontSize: '36px', color: 'red' }} />
                </div>
                <h1 className='h6 mt-2 mb-1 fw-normal'>
                  Something went wrong...
                </h1>
                <p style={{ fontSize: '12px' }}>
                  Try returning to the dashboard by clicking the button below.
                  For assistance, please send an sms to{' '}
                  <span>
                    <a href='sms:+1 587 404 2004'>+1 587 404 2004</a>
                  </span>{' '}
                  or send an email to{' '}
                  <a href='mailto:info@support.rotapad.com'>
                    info@support.rotapad.com
                  </a>
                </p>
              </div>
            )}

            {showMessage && (
              <div className='border-top border-bottom py-4 mb-4'>
                <div className='form-floating mb-2'>
                  <FiInfo style={{ fontSize: '36px', color: 'orange' }} />
                </div>
                {/* <h1 className='h6 mt-2 mb-1 fw-normal'>Infor</h1> */}
                <p style={{ fontSize: '12px' }}>
                  A password reset email has been sent to{' '}
                  <strong>{email}</strong> but If the email is not in your
                  in-box, check your spam folder. <br />
                  Otherwise, contact your designated RotaPad Administrator.
                </p>
              </div>
            )}

            <h1 className='h5 mb-3 fw-normal'>Forgot Password</h1>
            <p style={{ fontSize: '12px' }}>
              Enter your email address below. A password reset email will be
              sent to it.
            </p>
            <div className='form-floating mb-3'>
              <input
                type='email'
                className='form-control'
                placeholder='name@example.com'
                name='email'
                id='email'
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete='username'
              />
              <label>Email address</label>
            </div>

            <ButtonBase
              variant='primary'
              size='large'
              onClick={handleResetPassword}
              type='button'
            >
              Reset Password
              {isLoginLoading && (
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
              )}
            </ButtonBase>

            <StyledLinkDiv
              className='mt-4 mb-1'
              onClick={() => navigate('/')}
            >
              Back to Login
            </StyledLinkDiv>

            <p className='mt-3 mb-1 text-muted'>{footerTag}</p>
            {/* <p className='text-muted p-0'>{loginParagraph || ''}</p> */}
          </form>
        </ErrorStyledDiv>
      </div>
    </>
  );
};

ForgotPasswordPage.defaultProps = {
  theme: { primary: 'red' },
};
export default ForgotPasswordPage;
