// Login component

import styled from '@emotion/styled';
import {
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
// import { ref, set } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ButtonBase, ToolTip } from '../common';
import { TOAST_TYPE } from '../common/constants';
import { useBranding } from '../common/hooks/useBranding';
import { useToast } from '../common/hooks/useToast';
import Logo from '../common/img/logo.png';
import { FiInfo } from 'react-icons/fi';
import { MdOutlineRememberMe } from 'react-icons/md';
import { Field, Form } from 'react-final-form';
import { classNames } from 'primereact/utils';
import {
  useCreateUserWithEmailAndPassword,
  useUpdatePassword,
} from 'react-firebase-hooks/auth';
import zxcvbn from 'zxcvbn';
import { child, get, ref, remove, set, onValue } from 'firebase/database';
import _ from 'lodash';
import { convertObjectToArray, formatHour } from '../common/utils';
import moment from 'moment';
import {
  BsCheckCircle,
  BsCheckCircleFill,
  BsInfoCircle,
  BsXCircle,
} from 'react-icons/bs';
import { StyledInputTextArea } from 'components/workspace/AddWorkSpaceForm/styles';

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
  margin-top: 8px;
`;

const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;
// import './index.scss';
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
`;
const StyledDiv = styled.div`
  cursor: pointer;
  z-index: 200;
  position: absolute;
  right: 10px !important;
  font-size: large;
  color: #6c757d;
  top: 44px !important;

  &:hover {
    color: #7f00ff;
  }
`;

const ErrorStyledDiv = styled.div`
  background-color: #fff;
  padding: 24px;
`;

// const StyledInput = styled.input`
//   margin-bottom: 10px;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// `;

const CustomerApprovalPage = ({ app, analytics, auth, database }) => {
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [approved, setApproved] = useState('APPROVED');
  const [shift, setShift] = useState(null);
  const [comment, setComment] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [showErrMessage, setShowErrMessage] = useState(null);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetch = async () => {
      const dbRef = ref(database);
      // const getInvites = await get(child(dbRef, `invites`));

      const workOrders = await get(
        child(
          dbRef,
          `shifts/${searchParams.get('_year')}/${
            searchParams.get('_month').charAt(0).toUpperCase() +
            searchParams.get('_month').slice(1)
          }/${searchParams.get('_sid')}`
        )
      );

      if (workOrders.exists()) {
        // console.log(workOrders.val());
        setShift(workOrders.val());
        // const shift = workOrders.val();
        // const newShift = {
        //   attendees: shift.attendees.map((attendee) => {
        //     if (
        //       attendee.email ===
        //       searchParams.get('_atemail').split(' ').join('+')
        //     ) {
        //       attendee.approved = true;
        //       attendee.approvalDate = moment().format();
        //     }
        //     return attendee;
        //   }),
        //   ...shift,
        // };

        // // console.log('newShift ===== ', newShift);
        // await set(
        //   ref(
        //     database,
        //     `shifts/${moment().format('YYYY')}/${moment().format(
        //       'MMMM'
        //     )}/${searchParams.get('_sid')}`
        //   ),
        //   newShift
        // );
      } else {
        setShowErrMessage('No invitations available');
      }
    };

    if (
      searchParams &&
      searchParams.get('_sid') &&
      searchParams.get('_year') &&
      searchParams.get('_month')
    ) {
      // console.log('searchParams ==== ', searchParams.get('_sid'));
      // console.log(
      //   'searchParams ==== ',
      //   searchParams.get('_email').split(' ').join('+')
      // );
      // console.log('searchParams ==== ', searchParams.get('_month'));
      // console.log(
      //   'searchParams ==== ',
      //   searchParams.get('_atemail').split(' ').join('+')
      // );
      fetch();
    }
  }, [searchParams, auth]);
  // const { triggerToast } = useToast();

  const navigate = useNavigate();

  const { logo, footerTag } = useBranding();

  // Remove trailing route string and redirect to '/'
  // useEffect(() => {
  //   if (navigate || pathname) {
  //     navigate('/');
  //   }
  // }, [navigate, pathname]);

  const onOptionChange = (e) => {
    setApproved(e.target.value);
  };

  const handleSubmitApproval = useCallback(
    async (e) => {
      setShowMessage(false);
      setIsLoginLoading(true);
      setShowErrMessage(null);
      e.preventDefault();
      // const shift = workOrders.val();
      const newShift = {
        attendees: shift.attendees.map((attendee) => {
          if (
            attendee.email === searchParams.get('_atemail').split(' ').join('+')
          ) {
            attendee.approved = approved === 'APPROVED';
            attendee.approvalDate = moment().format();
            attendee.customerComment = comment;
          }
          return attendee;
        }),
        ...shift,
      };

      // console.log('newShift ===== ', newShift);
      await set(
        ref(
          database,
          `shifts/${moment(new Date(shift.start)).format('YYYY')}/${moment(
            new Date(shift.start)
          ).format('MMMM')}/${shift.uid}`
        ),
        newShift
      );

      setIsLoginLoading(false);
      setShowMessage(true);
    },
    [shift, approved, comment]
  );
  return (
    <>
      <div className={`formBody  `}>
        <ErrorStyledDiv className='shadow-sm form-signin w-100 m-auto'>
          <form noValidate>
            {logo?.fullLogo ? (
              <div className={` text-center `}>
                <img
                  src={logo?.fullLogo}
                  alt='Logo'
                  className='mb-2'
                />
                <br />
                <img
                  src={Logo}
                  width='100'
                  height='21'
                  className='mb-4'
                  alt='ROTA PAD logo'
                />
              </div>
            ) : (
              <img
                src={Logo}
                width='140'
                height='32'
                className='mb-4'
                alt='ROTA PAD logo'
              />
            )}
            <div className='border-top border-bottom py-4 mb-4'>
              {showMessage && (
                <div className='me-4 text-center'>
                  <div className='form-floating'>
                    <BsCheckCircle
                      style={{ fontSize: '36px', color: 'green' }}
                    />
                  </div>
                  <h1
                    className='h6 mt-2 mb-1 fw-normal'
                    style={{ color: 'green' }}
                  >
                    Submitted successfully!
                  </h1>
                </div>
              )}

              {showErrMessage ? (
                <div className='me-4 text-center'>
                  <div className='form-floating'>
                    <BsXCircle style={{ fontSize: '36px', color: 'orange' }} />
                  </div>
                  <h1
                    className='h6 mt-2 mb-1 fw-normal'
                    style={{ color: 'orange' }}
                  >
                    {showErrMessage}
                  </h1>
                </div>
              ) : (
                <>
                  {' '}
                  {shift ? (
                    <>
                      <div className='field'>
                        <div className='block'>
                          <StyledLabel htmlFor='description'>
                            <p>
                              Approve time entry for{' '}
                              <strong>
                                {moment(new Date(shift.start)).format(
                                  'dddd Do,'
                                )}{' '}
                                {moment(new Date(shift.start)).format(
                                  'MMMM YYYY'
                                )}
                              </strong>{' '}
                              for shift <strong>{shift.title}</strong> by{' '}
                              <strong>
                                {shift.attendees[0].firstname}{' '}
                                {shift.attendees[0].lastname}
                              </strong>{' '}
                              who is logging{' '}
                              <strong>
                                {formatHour(shift.attendees[0].timeEntry)}hrs
                              </strong>
                            </p>
                          </StyledLabel>
                          <div className='form-check mt-2'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='flexRadioDefault'
                              id='flexRadioDefault1'
                              checked={approved === 'APPROVED'}
                              value='APPROVED'
                              onChange={onOptionChange}
                            />
                            <label className='form-check-label'>Approve</label>
                          </div>
                          <div className='form-check mb-4'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='flexRadioDefault'
                              id='flexRadioDefault2'
                              checked={approved === 'DISAPPROVED'}
                              value='DISAPPROVED'
                              onChange={onOptionChange}
                            />
                            <label className='form-check-label'>
                              Disapprove
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className='field'>
                        <div className='block'>
                          <StyledInputTextArea
                            id='comment'
                            placeholder='Enter comment'
                            rows={5}
                            cols={30}
                            className='w-100 p-inputtext-sm'
                            value={comment || ''}
                            onChange={(x) => {
                              setComment(x.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  )}
                </>
              )}

              {/* <p style={{ fontSize: '12px' }}>
                For assistance, please call <br />
                <span>+1 587 404 2004</span> or send an email to
                support@rotapad.com
              </p> */}
            </div>

            <ButtonBase
              variant='primary'
              size='large'
              onClick={handleSubmitApproval}
              disabled={isLoginLoading || !shift}
            >
              Submit
              {isLoginLoading && (
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
              )}
            </ButtonBase>

            <p className='mt-5 mb-1 text-muted text-center'>{footerTag}</p>
            {/* <p className='text-muted p-0'>{loginParagraph || ''}</p> */}
          </form>
        </ErrorStyledDiv>
      </div>
    </>
  );
};

// RegisterPage.defaultProps = {
//   theme: { primary: 'red' },
// };
export default CustomerApprovalPage;
