import styled from '@emotion/styled';
import { BiGridAlt, BiIdCard, BiMap, BiPlus, BiTable } from 'react-icons/bi';
import { Badge, ToolTip } from '..';
import ButtonBase, { BTN_VARIANTS } from '../ButtonBase';
import { useApiService } from '../../context';
import { useState } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { AbilityContext } from 'components/context/ability-context';
import { useAbility } from '@casl/react';
import { MultiSelect } from 'primereact/multiselect';
import { useMedia } from 'react-use';
import { classNames } from 'primereact/utils';
import { StyledInput } from 'components/team/AddTeamMemberForm';
import { MdOutlineCancel } from 'react-icons/md';

export const StyledSearchInput = styled.input`
  padding: 4px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 0.5rem;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;
export const StyledSearchTermDeleteBtn = styled.div`
  cursor: pointer;
  color: #b2b2b2;

  &:hover {
    color: #333;
  }
`;
const StyledCard = styled.div`
  cursor: pointer;
  width: 12rem;
  font-size: 12px;
  padding-top: 20px;
  min-height: 175px;
  border: #af97d8 solid 3px;
  img {
    width: 70px;
  }

  div > svg {
    font-size: 34px;
    color: #af97d8;
  }
  &:hover {
    border: #b2d897 solid 3px;

    div > svg {
      color: #b2d897;
    }
  }
`;
const StyleViewBtn = styled.button`
  line-height: 14px;
  text-transform: uppercase;
  font-size: 11px !important;
`;
// const StyledInput = styled.input`
//   font-size: 12px !important;
//   line-height: 15px;
// `;
const StyledInfoDiv = styled.div`
  // cursor: pointer;
  font-size: 12px;
  background: #f3fbf5;
  &:hover {
    background: #fff;
  }
`;

const StyledTemplatesDiv = styled.div`
  background: #f5ebff;
  border-radius: 13px;
`;
const StyleNamesSpan = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: #555;
`;

const StyledHeaders = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #444;
  letter-spacing: 0.0543215rem;
`;

const StyledViewTypeDiv = styled.div`
  margin-top: 1px;
`;

const StyledDropDownDiv = styled.div`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  margin-top: -2px;
  min-width: 306px;
  .customDropdown {
    width: 100%;
    background-color: #fff;
    border: none;
    border-radius: 8px;
  }
  .customDropdown:not(.p-disabled):hover {
    border-color: #6366f1;
  }

  .customDropdown .p-dropdown-label,
  .customDropdown .p-multiselect-label {
    background: transparent;
    border: 0 none;
    padding: 3px 3px !important;
    font-size: 12px;
    // line-height: 22px;
  }

  .customDropdown .p-dropdown-trigger,
  .customDropdown .p-multiselect-trigger {
    width: 22px;
    margin-right: 6px;
  }

  .customDropdown .p-dropdown-trigger-icon,
  .customDropdown .p-multiselect-trigger-icon {
    font-size: 10px;
    width: 10px;
    height: 10px;
  }

  .customDropdown.p-multiselect-chip .p-multiselect-token {
    padding: 2px 3px;
    margin-right: 0.5rem;
    background: #e5e5e5;
    color: #495057;
    border-radius: 4px;
    font-size: 11px;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
    background-color: #e9ecef;
  }
`;
const FilterComponent = ({
  selectedFilterOptions,
  setSelectedFilterOptions,
}) => {
  const filterOptions = [
    { name: 'My shifts', code: 'myshifts' },
    { name: 'Assigned', code: 'assigned' },
    { name: 'Unassigned', code: 'unassigned' },
  ];

  return (
    <StyledDropDownDiv className='me-2 flex justify-content-center'>
      <MultiSelect
        value={selectedFilterOptions}
        onChange={(e) => {
          // console.log(e.value);
          setSelectedFilterOptions(e.value.filter((a) => a !== undefined));
        }}
        options={filterOptions}
        optionLabel='name'
        display='chip'
        placeholder='Select filters'
        maxSelectedLabels={3}
        className='w-full md:w-20rem customDropdown'
      />
    </StyledDropDownDiv>
  );
};
const PageTitleBar = ({
  title,
  count,
  badgeStyle,
  handleClickNext,
  handleClickPrev,
  handleClickAdd,
  isCardView,
  setIsCardView,
  canRotaPadUserUpdate = false,
  filtered = false,
  selectedFilterOptions,
  setSelectedFilterOptions,
  searchTerm,
  setSearchTerm,
  placeholder,
}) => {
  // isRotaPadUserAdmin
  // const [value, setValue] = useState(true);

  const { isRotaPadUserAdmin, rotaPadUser } = useApiService();
  const isWide = useMedia('(min-width: 768px)');

  // console.log('isWide ==== ', isWide);
  return (
    <div
      className={`d-flex ${
        isWide ? 'justify-content-between mb-3' : 'flex-column mb-0'
      } mt-0 pt-0 `}
    >
      <StyledHeaders>
        {title}
        {count && (
          <Badge
            value={count}
            variant={BTN_VARIANTS.primary}
          ></Badge>
        )}
      </StyledHeaders>
      <div>
        <div className='d-flex flex-row'>
          {/* <div className='btn-group input-group-sm ms-2 align-self-center'>
            <StyledInput
              type='text'
              className='form-control btn-sm'
              aria-label='Text input with dropdown button'
              placeholder={`Search ${title}`}
            />
          </div>
          <div className='btn-group input-group-sm ms-2 align-self-center'>
            <StyleViewBtn
              type='button'
              className='btn btn-sm btn-outline-secondary'
            >
              Filter
              <BiIdCard />
            </StyleViewBtn>
          </div> */}

          <div className='btn-toolbar my-2 my-sm-0 my-md-0'>
            <div
              className={`d-flex ${
                isWide ? 'flex-row mb-0' : 'flex-column mt-2'
              } `}
            >
              {filtered && (
                <FilterComponent
                  selectedFilterOptions={selectedFilterOptions}
                  setSelectedFilterOptions={setSelectedFilterOptions}
                />
              )}
              <div
                className={`d-flex flex-row mb-0 ${isWide ? '' : 'mt-2 mb-0'}`}
              >
                {setSearchTerm && (
                  <div className='field me-2 position-relative'>
                    <div className='block'>
                      <StyledSearchInput
                        // id='firstname'
                        placeholder={placeholder}
                        value={searchTerm || ''}
                        onChange={(x) => {
                          setSearchTerm(x.target.value);
                        }}
                        className={classNames({
                          'p-error': true,
                        })}
                      />
                    </div>
                    {searchTerm && (
                      <StyledSearchTermDeleteBtn
                        className='position-absolute top-0 end-0 pe-2'
                        onClick={() => {
                          setSearchTerm(null);
                        }}
                      >
                        <MdOutlineCancel />
                      </StyledSearchTermDeleteBtn>
                    )}
                  </div>
                )}
                {setIsCardView && (
                  <StyledViewTypeDiv className='d-flex justify-content-center'>
                    <div className='me-1'>
                      <ToolTip
                        placement='top'
                        overlay={
                          <span
                            style={{
                              fontSize: 10,
                            }}
                          >
                            Card view
                          </span>
                        }
                      >
                        <ButtonBase
                          type='button'
                          variant='primary'
                          size='small'
                          onClick={() => setIsCardView(true)}
                          disabled={isCardView}
                        >
                          <BiGridAlt style={{ fontSize: '15px' }} />
                        </ButtonBase>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip
                        placement='top'
                        overlay={
                          <span
                            style={{
                              fontSize: 10,
                            }}
                          >
                            Table view
                          </span>
                        }
                      >
                        <ButtonBase
                          type='submit'
                          variant='primary'
                          size='small'
                          onClick={() => setIsCardView(false)}
                          disabled={!isCardView}
                        >
                          <BiTable style={{ fontSize: '15px' }} />
                        </ButtonBase>
                      </ToolTip>
                    </div>
                  </StyledViewTypeDiv>
                )}

                {handleClickPrev && (
                  <div className='ms-2 me-1'>
                    <ButtonBase
                      type='button'
                      variant='primary'
                      size='small'
                      onClick={handleClickPrev}
                    >
                      Prev
                    </ButtonBase>
                  </div>
                )}

                {handleClickNext && (
                  <div>
                    <ButtonBase
                      type='submit'
                      variant='primary'
                      size='small'
                      onClick={handleClickNext}
                      // disabled={pristine || submitting || hasValidationErrors}
                    >
                      Next
                    </ButtonBase>
                  </div>
                )}
                {handleClickAdd && canRotaPadUserUpdate && (
                  <div className='ms-2'>
                    <ButtonBase
                      type='button'
                      variant='outline'
                      size='small'
                      onClick={handleClickAdd}
                    >
                      <BiPlus /> {` Add New `}
                    </ButtonBase>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitleBar;
