import { child, get, ref, remove, set, onValue } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { useParams } from 'react-router-dom';
import UserImg from '../common/img/user.png';
import moment from 'moment';
import { AbilityContext } from './ability-context';
import { AbilityBuilder } from '@casl/ability';
import {
  ABILITY_TYPES,
  TITLES,
  URLS,
  USER_TYPES,
} from 'components/common/constants';

function updateAbility(ability, role) {
  const { can, cannot, rules } = new AbilityBuilder();

  if (role === USER_TYPES.Admin) {
    can(ABILITY_TYPES.read, TITLES[`${URLS.dashboard}`]);
    can(ABILITY_TYPES.update, TITLES[`${URLS.dashboard}`]);

    can(ABILITY_TYPES.read, TITLES[`${URLS.team}`]);
    can(ABILITY_TYPES.update, TITLES[`${URLS.team}`]);

    can(ABILITY_TYPES.read, TITLES[`${URLS.groups}`]);
    can(ABILITY_TYPES.update, TITLES[`${URLS.groups}`]);

    can(ABILITY_TYPES.read, TITLES[`${URLS.absences}`]);
    can(ABILITY_TYPES.update, TITLES[`${URLS.absences}`]);

    can(ABILITY_TYPES.read, TITLES[`${URLS.customers}`]);
    can(ABILITY_TYPES.update, TITLES[`${URLS.customers}`]);

    can(ABILITY_TYPES.read, TITLES[`${URLS.reports}`]);
    can(ABILITY_TYPES.update, TITLES[`${URLS.reports}`]);

    can(ABILITY_TYPES.read, TITLES[`${URLS.profile}`]);
    can(ABILITY_TYPES.update, TITLES[`${URLS.profile}`]);

    // cannot('delete', TITLES[`${URLS.profile}`]);
  } else if (role === USER_TYPES.Support) {
    can(ABILITY_TYPES.read, TITLES[`${URLS.dashboard}`]);
    can(ABILITY_TYPES.update, TITLES[`${URLS.dashboard}`]);

    can(ABILITY_TYPES.read, TITLES[`${URLS.team}`]);
    // can(ABILITY_TYPES.update, TITLES[`${URLS.team}`]);

    can(ABILITY_TYPES.read, TITLES[`${URLS.groups}`]);
    // can(ABILITY_TYPES.update, TITLES[`${URLS.groups}`]);

    can(ABILITY_TYPES.read, TITLES[`${URLS.absences}`]);
    can(ABILITY_TYPES.update, TITLES[`${URLS.absences}`]);

    can(ABILITY_TYPES.read, TITLES[`${URLS.profile}`]);
    can(ABILITY_TYPES.update, TITLES[`${URLS.profile}`]);
  } else {
    can(ABILITY_TYPES.read, TITLES[`${URLS.dashboard}`]);
    // can(ABILITY_TYPES.update, TITLES[`${URLS.dashboard}`]);
    // can(ABILITY_TYPES.update, TITLES[`${URLS.dashboard}`]);
    can(ABILITY_TYPES.read, TITLES[`${URLS.team}`]);
    can(ABILITY_TYPES.read, TITLES[`${URLS.groups}`]);
    can(ABILITY_TYPES.read, TITLES[`${URLS.absences}`]);

    can(ABILITY_TYPES.read, TITLES[`${URLS.profile}`]);
    can(ABILITY_TYPES.update, TITLES[`${URLS.profile}`]);
    cannot(ABILITY_TYPES.delete, TITLES[`${URLS.profile}`]);
  }

  ability.update(rules);
}

const ApiServiceContext = createContext(null);
export const useApiService = () => useContext(ApiServiceContext);
export const ApiServiceProvider = ({
  user,
  auth,
  database,
  storage,
  children,
}) => {
  const ability = useContext(AbilityContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const params = useParams();

  const [rotaPadUser, setRotaPadUser] = useState();
  const [rotaPadShifts, setRotaPadShifts] = useState();
  const [rotaPadTeam, setRotaPadTeam] = useState();

  const [rotaPadTeamMembersTopic, setRotaPadTeamMembersTopic] = useState();
  const [rotaPadUserGroupsTopic, setRotaPadUserGroupsTopic] = useState();
  const [rotaPadAbsencesTopic, setRotaPadAbsencesTopic] = useState();
  const [rotaPadCustomersTopic, setRotaPadCustomersTopic] = useState();
  const [rotaPadShiftsTopic, setRotaPadShiftsTopic] = useState();
  const [rotaPadAccountSettingsTopic, setRotaPadAccountSettingsTopic] =
    useState();

  const [rotaPadPrinterTopic, setRotaPadPrinterTopic] = useState();
  const [rotaPadInvitesTopic, setRotaPadInvitesTopic] = useState();

  const [isRotaPadUserAdmin, setIsRotaPAdUserAdmin] = useState(false);
  const [isRotaPadUserSupport, setIsRotaPAdUserSupport] = useState(false);

  const [rotaPadGroups, setRotaPadGroups] = useState();
  const [rotaPadAbsences, setRotaPadAbsences] = useState();
  const [rotaPadWorkSpaces, setRotaPadWorkSpaces] = useState();
  const [rotaPadActiveWorkSpace, setRotaPadActiveWorkSpace] = useState();
  const [rotaPadWorkflows, setRotaPadWorkflows] = useState();

  const [rotaPadWorkOrders, setRotaPadWorkOrders] = useState();
  const [rotaPadInvitations, setRotaPadInvitations] = useState();
  const [userImg] = useDownloadURL(
    storageRef(storage, '/' + user.uid + '/file.png')
  );

  // const [profileImage, setProfileImage] = useState(userImg || UserImg);

  const [profileImage, setProfileImage] = useState(UserImg);
  // const [companyLogo, setCompanyLogo] = useState(UserImg);

  useEffect(() => {
    if (userImg) {
      setProfileImage(userImg);
    }
  }, [userImg]);

  // useEffect(() => {
  // 	const fetch = async (id) => {
  // 		const dbRef = ref(database);

  // 		console.log("id  &&&&&&&&&&&&&&&&&&&&&&& ", id);

  // 		const workspaces = await get(child(dbRef, `workspaces/${id}`));

  // 		if (workspaces.exists()) {
  // 			setRotaPadActiveWorkSpace(workspaces.val());
  // 		} else {
  // 			console.log("No workspace data available");
  // 		}
  // 	};
  // 	if (params) {
  // 		console.log("params =================== ", params);
  // 		fetch(params.Id);
  // 	}
  // }, [database, params, user]);

  useEffect(() => {
    const fetchUsers = () => {
      const query = ref(database, `users`);

      return onValue(query, (snapshot) => {
        // console.log('snapshot ==== ', snapshot.val());
        if (snapshot.exists()) {
          setRotaPadTeamMembersTopic(snapshot.val());
        } else {
          setRotaPadTeamMembersTopic();
        }
      });
    };

    const fetchUserGroups = () => {
      const query = ref(database, `groups`);

      return onValue(query, (snapshot) => {
        // console.log('snapshot ==== ', snapshot.val());
        if (snapshot.exists()) {
          setRotaPadUserGroupsTopic(snapshot.val());
        } else {
          setRotaPadUserGroupsTopic();
        }
      });
    };

    const fetchAbsences = () => {
      const query = ref(database, `absences`);

      return onValue(query, (snapshot) => {
        // console.log('snapshot ==== ', snapshot.val());
        if (snapshot.exists()) {
          setRotaPadAbsencesTopic(snapshot.val());
        } else {
          setRotaPadAbsencesTopic();
        }
      });
    };

    const fetchCustomers = () => {
      const query = ref(database, `customers`);

      return onValue(query, (snapshot) => {
        // console.log('snapshot ==== ', snapshot.val());
        if (snapshot.exists()) {
          setRotaPadCustomersTopic(snapshot.val());
        } else {
          setRotaPadCustomersTopic();
        }
      });
    };

    const fetchShifts = () => {
      const query = ref(database, `shifts`);

      return onValue(query, (snapshot) => {
        // console.log('snapshot ==== ', snapshot.val());
        if (snapshot.exists()) {
          setRotaPadShiftsTopic(snapshot.val());
        } else {
          setRotaPadShiftsTopic();
        }
      });
    };

    const fetchAccountSettings = () => {
      const query = ref(database, `settings`);

      return onValue(query, (snapshot) => {
        // console.log('snapshot ==== ', snapshot.val());
        if (snapshot.exists()) {
          const response = snapshot.val();

          setRotaPadAccountSettingsTopic(response);
        } else {
          setRotaPadAccountSettingsTopic();
        }
      });
    };

    const fetchPrinter = () => {
      const query = ref(database, `printer`);

      return onValue(query, (snapshot) => {
        // console.log('snapshot ==== ', snapshot.val());
        if (snapshot.exists()) {
          const response = snapshot.val();

          setRotaPadPrinterTopic(response);
        } else {
          setRotaPadPrinterTopic();
        }
      });
    };

    const fetchInvites = () => {
      const query = ref(database, `invites`);

      return onValue(query, (snapshot) => {
        // console.log('snapshot ==== ', snapshot.val());
        if (snapshot.exists()) {
          const response = snapshot.val();

          setRotaPadInvitesTopic(response);
        } else {
          setRotaPadInvitesTopic();
        }
      });
    };

    // setRotaPadInvitesTopic
    if (user) {
      fetchUsers();
      fetchUserGroups();
      fetchAbsences();
      fetchCustomers();
      fetchShifts();
      fetchAccountSettings();
      fetchPrinter();
      fetchInvites();
    }
  }, [database, user]);

  useEffect(() => {
    // const fetch2 = async (id) => {
    // 	const dbRef = ref(database);

    // 	console.log("id  &&&&&&&&&&&&&&&&&&&&&&& ", id);

    // 	const workspaces = await get(child(dbRef, `workspaces/${id}`));

    // 	if (workspaces.exists()) {
    // 		setRotaPadActiveWorkSpace(workspaces.val());
    // 	} else {
    // 		console.log("No workspace data available");
    // 	}
    // };
    // if (params) {
    // 	console.log("params =================== ", params);
    // 	fetch2(params.Id);
    // }
    const fetch = async () => {
      const dbRef = ref(database);
      const getUser = await get(child(dbRef, `users/${user.uid}`));
      // const getShifts = await get(
      //   child(dbRef, `shifts/${moment().format('YYYY')}`)
      // );
      // const users = await get(child(dbRef, `users`));
      // const groups = await get(child(dbRef, `groups`));
      // const invites = await get(child(dbRef, `invitations`));
      // const absences = await get(child(dbRef, `absences`));
      // const workOrders = await get(child(dbRef, `workspace/workorders`));

      // const workspaces = await get(child(dbRef, `workspaces`));
      // const workflows = await get(child(dbRef, `workflows`));

      // if (getShifts.exists()) {
      //   setRotaPadShifts(getShifts.val());
      // } else {
      //   console.log('No shifts data available');
      // }

      // if (users.exists()) {
      //   setRotaPadTeam(users.val());
      // } else {
      //   console.log('No users data available');
      // }

      // if (workspaces.exists()) {
      //   setRotaPadWorkSpaces(workspaces.val());
      // } else {
      //   console.log('No users data available');
      // }

      // if (workflows.exists()) {
      //   setRotaPadWorkflows(workflows.val());
      // } else {
      //   console.log('No workflows data available');
      // }

      // if (absences.exists()) {
      //   setRotaPadAbsences(absences.val());
      // } else {
      //   console.log('No absences data available');
      // }

      // if (workOrders.exists()) {
      //   setRotaPadWorkOrders(workOrders.val());
      // } else {
      //   console.log('No WO data available');
      // }

      // if (groups.exists()) {
      //   setRotaPadGroups(groups.val());
      // } else {
      //   console.log('No groups data available');
      // }

      // if (invites.exists()) {
      //   setRotaPadInvitations(invites.val());
      // } else {
      //   console.log('No invitations available');
      // }

      if (getUser.exists()) {
        // console.log('getUser.val() ==== ', getUser.val());
        updateAbility(ability, getUser.val().role);
        setRotaPadUser(getUser.val());
        setIsRotaPAdUserAdmin(getUser.val().role === USER_TYPES.Admin);
        setIsRotaPAdUserSupport(getUser.val().role === USER_TYPES.Support);
      } else {
        console.log('No user data available');
      }
    };
    if (user) {
      fetch();
    }
  }, [database, user, ability]);

  const fetchProfileImageData = async ({ path }) => {
    const dbRef = storageRef(storage, path);
    // const getUser = await getDownloadURL(dbRef)
    const imgUrl = await getDownloadURL(dbRef);
    return imgUrl;
  };

  const fetchData = async ({ db, path }) => {
    setIsLoading(true);
    setError(null);
    try {
      const dbRef = ref(db);
      const user = await get(child(dbRef, path));
      if (user.exists()) {
        setIsLoading(false);
        return user.val();
      } else {
        console.log('No user data available');
        setIsLoading(false);
        return 'No user data available';
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };
  // Create a document
  const createDoc = async (db, path, data, callBackFn) => {
    setIsLoading(true);
    try {
      // const dbRef = ref(db);
      await set(ref(db, path), data);
      const dbRef = ref(database);
      const user = await get(child(dbRef, path));
      if (user.exists()) {
        setIsLoading(false);
        if (callBackFn) {
          callBackFn(user.val());
        }
        return user.val();
      } else {
        console.log('No user data available');
        setIsLoading(false);
        return 'No user data available';
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
      // throw new Error("Failed to create document");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteDoc = async ({ db, path }) => {
    setIsLoading(true);
    setError(null);
    try {
      const dbRef = ref(db);
      const user = await remove(child(dbRef, path));
      if (user.exists()) {
        console.log('deleteDoc', user.val());
        setIsLoading(false);
        return user.val();
      } else {
        console.log('No user data available');
        setIsLoading(false);
        return 'No user data available';
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const value = {
    isLoading,
    error,
    fetchData,
    createDoc,
    user,
    auth,
    database,
    rotaPadUser,
    setRotaPadUser,
    storage,
    profileImage,
    fetchProfileImageData,
    setProfileImage,
    rotaPadTeam,
    rotaPadGroups,
    setRotaPadGroups,
    deleteDoc,
    rotaPadInvitations,
    setRotaPadInvitations,
    rotaPadAbsences,
    setRotaPadAbsences,
    setRotaPadTeam,
    rotaPadWorkOrders,
    setRotaPadWorkOrders,
    rotaPadWorkSpaces,
    setRotaPadWorkSpaces,
    rotaPadActiveWorkSpace,
    setRotaPadActiveWorkSpace,
    rotaPadWorkflows,
    setRotaPadWorkflows,
    rotaPadShifts,
    setRotaPadShifts,
    rotaPadTeamMembersTopic,
    rotaPadUserGroupsTopic,
    rotaPadAbsencesTopic,
    rotaPadCustomersTopic,
    rotaPadShiftsTopic,
    rotaPadAccountSettingsTopic,
    rotaPadPrinterTopic,
    setRotaPadPrinterTopic,
    rotaPadInvitesTopic,
    setRotaPadInvitesTopic,
    isRotaPadUserAdmin,
    isRotaPadUserSupport,
  };
  return (
    <ApiServiceContext.Provider value={value}>
      {children}
    </ApiServiceContext.Provider>
  );
};
