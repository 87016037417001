import styled from '@emotion/styled';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { BsPeople } from 'react-icons/bs';
import { PageTitleBar } from '../common';
import { ABILITY_TYPES, BADGE_STYLE, TITLES, URLS } from '../common/constants';
import { useApiService } from '../context'; //theme
import AddInvitationFormContainer from '../invitations/AddInvitationFormContainer';
import AddTeamMemberFormContainer from './AddTeamMemberForm';
import Card from './Card';
import TeamMembersTable from './Table';
import { convertObjectToArray } from 'components/common/utils';
import { AbilityContext } from 'components/context/ability-context';
import { useAbility } from '@casl/react';

const StyledCard = styled.div`
  cursor: pointer;
  width: 12rem;
  font-size: 12px;
  padding-top: 20px;
  min-height: 175px;
  border: #af97d8 solid 3px;
  img {
    width: 70px;
  }

  div > svg {
    font-size: 34px;
    color: #af97d8;
  }
  &:hover {
    border: #b2d897 solid 3px;

    div > svg {
      color: #b2d897;
    }
  }
`;
const StyleViewBtn = styled.button`
  line-height: 14px;
  text-transform: uppercase;
  font-size: 11px !important;
`;
const StyledInput = styled.input`
  font-size: 12px !important;
  line-height: 15px;
`;
const StyledInfoDiv = styled.div`
  // cursor: pointer;
  font-size: 12px;
  background: #f3fbf5;
  &:hover {
    background: #fff;
  }
`;

const StyledTemplatesDiv = styled.div`
  background: #f5ebff;
  border-radius: 13px;
`;
const StyleNamesSpan = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: #555;
`;

const StyledHeaders = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #444;
  letter-spacing: 0.0543215rem;
`;
const Team = () => {
  const {
    rotaPadUser,
    auth,
    rotaPadTeamMembersTopic,
    rotaPadInvitesTopic,
    isRotaPadUserAdmin,
  } = useApiService();
  const ability = useAbility(AbilityContext);
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [visibleInviteLeft, setVisibleInviteLeft] = useState(false);
  const [isCardView, setIsCardView] = useState(true);
  const [isCardViewInvites, setIsCardViewInvites] = useState(true);

  const [newInvite, setNewInvite] = useState({
    email: '',
    role: '',
    group: '',
    firstname: '',
    lastname: '',
    uid: undefined,
    active: true,
    endUserConsent: false,
    payRate: 0,
    createdAt: moment().format(),
  });

  const [updateUser, setUpdateUser] = useState();
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchInviteTerm, setSearchInviteTerm] = useState(null);

  const canRotaPadUserUpdate = useMemo(
    () =>
      ability &&
      ability.can(ABILITY_TYPES.read, TITLES[`${URLS.team}`]) &&
      ability.can(ABILITY_TYPES.update, TITLES[`${URLS.team}`]),
    [ability, rotaPadUser]
  );

  const getSearchResult = useMemo(() => {
    const getArray = convertObjectToArray({ item: rotaPadTeamMembersTopic });
    return searchTerm === '' || searchTerm === null
      ? getArray
      : getArray.filter((person) => {
          // console.log('person ==== ', person.firstname);
          // return true;
          return (
            person.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            person.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            person.email.toLowerCase().includes(searchTerm.toLowerCase())
          );
        });
  }, [searchTerm, rotaPadTeamMembersTopic]);

  const getSearchInvitesResult = useMemo(() => {
    const getArray = convertObjectToArray({ item: rotaPadInvitesTopic });
    return searchInviteTerm === '' || searchInviteTerm === null
      ? getArray
      : getArray.filter((person) => {
          // console.log('person ==== ', person.firstname);
          // return true;
          return (
            person.firstname
              .toLowerCase()
              .includes(searchInviteTerm.toLowerCase()) ||
            person.lastname
              .toLowerCase()
              .includes(searchInviteTerm.toLowerCase()) ||
            person.email.toLowerCase().includes(searchInviteTerm.toLowerCase())
          );
        });
  }, [searchInviteTerm, rotaPadInvitesTopic]);

  // console.log('rotaPadTeamMembersTopic ==== ', rotaPadTeamMembersTopic);
  // console.log('rotaPadTeam ==== ', rotaPadTeam);
  if (!rotaPadUser || !rotaPadTeamMembersTopic) return null;

  // console.log("Team rotaPadTeam $$$$$$$$$$$$$$$$$$$$$$$$$ ", rotaPadTeam);
  // const handleClickAdd = useCallback(() => {
  //   setNewGroup({
  //     start: '',
  //     end: '',
  //     title: '',
  //     description: '',
  //     type: '',
  //     customer: '',
  //     attendees: [],
  //     // id: uuidv4(),
  //     uid: undefined,
  //     createdAt: moment().format(),
  //   });
  //   setVisibleLeft(true);
  // }, []);

  // console.log('getSearchResult ===== ', getSearchResult);
  return (
    <div className='container-fluid mx-0 px-0'>
      <div className='row'>
        <div className='col-12 mt-0'>
          <AddInvitationFormContainer
            visibleLeft={visibleInviteLeft}
            setVisibleLeft={setVisibleInviteLeft}
            auth={auth}
            newGroup={newInvite}
            setNewGroup={setNewInvite}
          />

          <AddTeamMemberFormContainer
            visibleLeft={visibleLeft}
            setVisibleLeft={setVisibleLeft}
            auth={auth}
            newGroup={updateUser}
            setNewGroup={setUpdateUser}
          />

          <PageTitleBar
            title='Team Members'
            count={getSearchResult.length === 0 ? '0' : getSearchResult.length}
            badgeStyle={BADGE_STYLE.Menu}
            handleClickAdd={() => setVisibleInviteLeft(true)}
            isCardView={isCardView}
            setIsCardView={setIsCardView}
            canRotaPadUserUpdate={canRotaPadUserUpdate}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            placeholder='Search...'
          />
          {/* rotaPadInvitesTopic */}
          {getSearchResult.length > 0 ? (
            <div className='container-fluid py-2 px-0'>
              <div className='row g-3 '>
                {!isCardView && (
                  <TeamMembersTable
                    // shifts={[]}
                    shifts={_.sortBy(getSearchResult, ['start'])}
                    setShiftObj={setUpdateUser}
                    shiftObj={updateUser}
                    setShiftVisibleRight={setVisibleLeft}
                    isUserAdmin={canRotaPadUserUpdate}
                    customers={[]}
                    type='teamMember'
                  />
                )}

                {isCardView && (
                  <>
                    {getSearchResult.map((i) => {
                      return (
                        <Card
                          key={i.uid}
                          setNewGroup={setUpdateUser}
                          setVisibleLeft={setVisibleLeft}
                          canRotaPadUserUpdate={canRotaPadUserUpdate}
                          type='teamMember'
                          {...i}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className='card mt-3 text-center'>
                <div className='mt-4 pt-4 pb-2 text-muted'>
                  <BsPeople style={{ fontSize: '5rem', color: '#ad8fdb' }} />
                </div>
                <div className='d-flex justify-content-center px-4 mb-2'>
                  <div className='text-muted w-50'>
                    {searchTerm && (
                      <div className=''>
                        <strong>{searchTerm} </strong>does not match any
                        exisiting team member record.
                      </div>
                    )}
                    <div className=''>
                      Invite others to join your workspace. You can also allow
                      members to sign up using your company’s email domain.
                      Learn more
                    </div>
                    <div className='pt-4 mb-4 pb-3'>
                      <button
                        type='button'
                        className='btn btn-sm btn-primary btnPrimary me-0'
                        onClick={() => setVisibleLeft(true)}
                      >
                        <i
                          className='pi pi-user-plus'
                          style={{
                            fontSize: '12px',
                            marginRight: '4px',
                          }}
                        ></i>
                        Send an invite
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {isRotaPadUserAdmin && (
            <>
              <div className='container-fluid py-2 px-0 mt-3'>
                <PageTitleBar
                  title='Invitations'
                  count={
                    getSearchInvitesResult.length === 0
                      ? '0'
                      : getSearchInvitesResult.length
                  }
                  badgeStyle={BADGE_STYLE.Menu}
                  isCardView={isCardViewInvites}
                  setIsCardView={setIsCardViewInvites}
                  searchTerm={searchInviteTerm}
                  setSearchTerm={setSearchInviteTerm}
                  placeholder='Search invitations...'
                />
                {getSearchInvitesResult.length > 0 ? (
                  <div className='row g-3 mt-1'>
                    {!isCardViewInvites && (
                      <TeamMembersTable
                        // shifts={[]}
                        shifts={_.sortBy(getSearchInvitesResult, ['start'])}
                        setShiftObj={setUpdateUser}
                        shiftObj={updateUser}
                        setShiftVisibleRight={setVisibleLeft}
                        isUserAdmin={isRotaPadUserAdmin}
                        customers={[]}
                        type='invite'
                      />
                    )}

                    {isCardViewInvites && (
                      <>
                        {getSearchInvitesResult.map((i) => {
                          return (
                            <Card
                              key={i.uid}
                              setNewGroup={setUpdateUser}
                              setVisibleLeft={setVisibleLeft}
                              canRotaPadUserUpdate={canRotaPadUserUpdate}
                              type='invite'
                              {...i}
                            />
                          );
                        })}
                      </>
                    )}
                  </div>
                ) : (
                  <div className='card mt-3 text-center'>
                    <div className='mt-4 pt-4 pb-2 text-muted'>
                      <BsPeople
                        style={{ fontSize: '5rem', color: '#ad8fdb' }}
                      />
                    </div>
                    <div className='d-flex justify-content-center px-4 mb-2'>
                      <div className='text-muted w-50'>
                        {searchInviteTerm && (
                          <div className=''>
                            <strong>{searchInviteTerm} </strong>does not match
                            any exisiting invitations.
                          </div>
                        )}
                        <div className=''>
                          Invite others to join your workspace. You can also
                          allow members to sign up using your company’s email
                          domain. Learn more
                        </div>
                        <div className='pt-4 mb-4 pb-3'>
                          <button
                            type='button'
                            className='btn btn-sm btn-primary btnPrimary me-0'
                            onClick={() => setVisibleLeft(true)}
                          >
                            <i
                              className='pi pi-user-plus'
                              style={{
                                fontSize: '12px',
                                marginRight: '4px',
                              }}
                            ></i>
                            Send an invite
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Team;
