import { BiUser } from 'react-icons/bi';
import {
  ApprovedStyledDiv,
  RoundedCircle,
  RoundedPill,
  StyledCardItem,
} from '..';
import TimeEntryContainer from '../TimeEntryContainer';
import CustomerApprovalContainer from '../CustomerApprovalContainer';
import { ToolTip } from 'components/common';
import CustomerComment from '../CustomerComment';
import Dropdown from 'components/common/Dropdown';
import { FiX } from 'react-icons/fi';
import { useState } from 'react';

const CardBodyItem = ({
  attendee,
  isRotaPadUserAdmin,
  rotaPadUser,
  start,
  uid,
  title,
  handleAddAttendeeHours,
  handleAddCustomerApprovalHours,
  customer,
  handleRemoveAttendee,
  handleAddAttendee,
  isAssigned,
  onClick,
  setVisibleLeft,
}) => {
  const [visible, setVisible] = useState(false);
  const [visibleCAF, setVisibleCAF] = useState(false);
  return (
    <>
      <StyledCardItem isAssigned={isAssigned}>
        <div className='d-flex align-items-center'>
          <div>
            <BiUser
              style={{
                marginRight: '12px',
                marginTop: '-2px',
                fontSize: '1.35rem',
              }}
            />
          </div>
          <div>
            <div className='d-flex flex-column'>
              <div className='text-truncate'>
                {attendee.firstname + ' ' + attendee.lastname}
              </div>
              <div>
                {start && (
                  <div className='d-flex flex-row'>
                    <div style={{ marginLeft: '-8px' }}>
                      <TimeEntryContainer
                        isRotaPadUserAdmin={isRotaPadUserAdmin}
                        attendee={attendee}
                        rotaPadUser={rotaPadUser}
                        start={start}
                        uid={uid}
                        title={title}
                        handleAddAttendeeHours={handleAddAttendeeHours}
                        visible={visible}
                        setVisible={setVisible}
                      />
                    </div>
                    <div>
                      {' '}
                      {!attendee?.approved &&
                        attendee?.timeEntry !== undefined &&
                        attendee?.timeEntry !== 0 && (
                          <CustomerApprovalContainer
                            isRotaPadUserAdmin={isRotaPadUserAdmin}
                            attendee={attendee}
                            rotaPadUser={rotaPadUser}
                            start={start}
                            uid={uid}
                            title={title}
                            handleAddAttendeeHours={
                              handleAddCustomerApprovalHours
                            }
                            customer={customer}
                            visible={visibleCAF}
                            setVisible={setVisibleCAF}
                          />
                        )}
                    </div>
                    <div>
                      {attendee?.approved !== undefined &&
                        attendee?.approved &&
                        attendee?.timeEntry !== 0 && (
                          <div className='text-truncate me-1'>
                            <ToolTip
                              placement='top'
                              overlay={
                                <span
                                  style={{
                                    fontSize: 10,
                                  }}
                                >
                                  Customer approved!
                                </span>
                              }
                            >
                              <ApprovedStyledDiv
                                active={
                                  !isRotaPadUserAdmin &&
                                  attendee.email === rotaPadUser.email
                                }
                                // onClick={() => setVisible(true)}
                              >
                                {/* <FiCheckSquare
                style={
                  {
                    // marginRight: '4px',
                    // marginTop: '-4px',
                    // fontSize: '0.85rem',
                  }
                }
              /> */}
                                APPROVED
                              </ApprovedStyledDiv>
                            </ToolTip>
                          </div>
                        )}
                    </div>
                    <div>
                      {attendee.customerComment && (
                        <CustomerComment
                          attendee={attendee}
                          customer={customer}
                        />
                      )}
                    </div>
                  </div>
                )}
                {!isAssigned && (
                  <ApprovedStyledDiv
                    active={false}
                    style={{ marginLeft: '-2px' }}
                    // onClick={() => setVisible(true)}
                  >
                    {/* <FiCheckSquare
                style={
                  {
                    // marginRight: '4px',
                    // marginTop: '-4px',
                    // fontSize: '0.85rem',
                  }
                }
              /> */}
                    UNASSIGNED
                  </ApprovedStyledDiv>
                )}
              </div>
            </div>
          </div>
          <div className='ms-auto'>
            {/* <RoundButtonBase
          variant='outline'
          type='button'
          size='small'
          className={` me-2 `}
        >
          
        </RoundButtonBase> */}

            <Dropdown
              setVisibleCAF={setVisibleCAF}
              setVisibleTEF={setVisible}
              isAssigned={isAssigned}
              isApproved={
                attendee?.approved !== undefined && attendee?.approved
              }
              handleRemoveAttendee={handleRemoveAttendee}
              handleAddAttendee={handleAddAttendee}
              canSendApprovalRequest={
                !attendee?.approved &&
                attendee?.timeEntry !== undefined &&
                attendee?.timeEntry !== 0
              }
              handleOpenShift={onClick}
              setVisibleLeft={setVisibleLeft}
              isUser={attendee?.email === rotaPadUser?.email}
            />
            {/* {!isRotaPadUserAdmin && ( */}
            {/* {(attendee?.email === rotaPadUser?.email || !isAssigned) && (
              <Dropdown
                setVisibleCAF={setVisibleCAF}
                setVisibleTEF={setVisible}
                isAssigned={isAssigned}
                isApproved={
                  attendee?.approved !== undefined && attendee?.approved
                }
                handleRemoveAttendee={handleRemoveAttendee}
                handleAddAttendee={handleAddAttendee}
                canSendApprovalRequest={
                  !attendee?.approved &&
                  attendee?.timeEntry !== undefined &&
                  attendee?.timeEntry !== 0
                }
                handleOpenShift={onClick}
                setVisibleLeft={setVisibleLeft}
              />
            )} */}
          </div>
        </div>
      </StyledCardItem>
    </>
  );
};

export default CardBodyItem;
