import styled from '@emotion/styled';
import { useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { useApiService } from '../context';
import UpdatePhoto from './UpdatePhoto';
import UpdatePwdForm from './UpdatePwdForm';
import UserAddressForm from './UserAddressForm';
import UserProfileForm from './UserProfileForm';
import AccountSettings from './AccountSettings';
import { useMedia } from 'react-use';
import { useBranding } from 'components/common/hooks/useBranding';

const StyledTabHeader = styled.span`
  cursor: pointer;
  font-size: 13px;
  &:hover {
    background: #fff;
  }
`;

const StyledInfoDiv = styled.div`
  // cursor: pointer;
  font-size: 12px;
  background: #f3fbf5;
  &:hover {
    background: #fff;
  }
`;

const MyProfile = () => {
  const { logo, loginParagraph } = useBranding();
  const { rotaPadUser, isRotaPadUserAdmin } = useApiService();
  const [active, setActive] = useState(0);
  const isWide = useMedia('(min-width: 480px)');

  if (!rotaPadUser) return null;

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-sm-12 col-md-8'>
          <div>
            <div className='d-flex flex-column '>
              <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center '>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: '13px',
                    marginTop: '0px',
                    color: '#7266ba96',
                  }}
                >
                  <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                      <li
                        className='breadcrumb-item active'
                        aria-current='page'
                      >
                        <BiUser
                          style={{ marginRight: '8px', marginTop: '-4px' }}
                        />
                        My Profile
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>

            <ul
              className='nav nav-tabs mt-34'
              style={{ borderBottom: 'none' }}
            >
              <li
                className='nav-item text-truncate'
                onClick={() => setActive(0)}
              >
                <StyledTabHeader
                  className={`text-truncate nav-link ${
                    active === 0 ? 'active' : ''
                  }`}
                  style={{ width: isWide ? 'inherit' : '100px' }}
                >
                  Your Information
                </StyledTabHeader>
              </li>
              <li
                className='nav-item text-truncate'
                onClick={() => setActive(1)}
              >
                <StyledTabHeader
                  className={` text-truncate nav-link ${
                    active === 1 ? 'active' : ''
                  }`}
                  style={{ width: isWide ? 'inherit' : '100px' }}
                >
                  Security and Privacy
                </StyledTabHeader>
              </li>
              {isRotaPadUserAdmin !== undefined && isRotaPadUserAdmin && (
                <li
                  className='nav-item text-truncate'
                  onClick={() => setActive(2)}
                >
                  <StyledTabHeader
                    className={`text-truncate nav-link ${
                      active === 2 ? 'active' : ''
                    }`}
                    style={{ width: isWide ? 'inherit' : '120px' }}
                  >
                    Account Settings
                  </StyledTabHeader>
                </li>
              )}

              {/* <li
                className='nav-item'
                onClick={() => setActive(3)}
              >
                <StyledTabHeader
                  className={`nav-link ${active === 3 ? 'active' : ''}`}
                >
                  Location Services
                </StyledTabHeader>
              </li> */}
            </ul>
            <div
              className='py-3 px-3 border border-top rounded-bottom rounded-end rounded-end mb-4'
              style={{ background: '#fff' }}
            >
              {active === 0 && <UserProfileForm rotaPadUser={rotaPadUser} />}
              {active === 1 && <UpdatePwdForm rotaPadUser={rotaPadUser} />}
              {isRotaPadUserAdmin !== undefined &&
                isRotaPadUserAdmin &&
                active === 2 && <AccountSettings rotaPadUser={rotaPadUser} />}
              {/* {active === 3 && <UserAddressForm rotaPadUser={rotaPadUser} />} */}
            </div>
          </div>
        </div>
        <div className='col-sm-12 col-md-3 ms-0 '>
          <div className='mb-4 mt-0 ms-2'>
            <UpdatePhoto rotaPadUser={rotaPadUser} />
          </div>
          <StyledInfoDiv
            className='alert alert-success ms-2'
            role='alert'
          >
            <h6 className='alert-heading'>Getting support</h6>
            <p>
              Aww yeah, you successfully read this important alert message. More
              help can be found on the RotaPad Help Centre.
            </p>
            <hr />
            <p className='mb-0'>
              For assistance, please send an sms to{' '}
              <span>
                <a href='sms:+1 587 404 2004'>+1 587 404 2004</a>
              </span>{' '}
              or send an email to{' '}
              <a href='mailto:info@support.rotapad.com'>
                info@support.rotapad.com
              </a>
            </p>
          </StyledInfoDiv>

          <div className='d-flex flex-row mb-3 pt-3 ps-2 border-top'>
            <div>
              <img
                src={logo?.qrCode}
                alt='qrCode'
                className='mb-2'
                style={{ width: '80%' }}
              />
            </div>
            <div style={{ fontSize: '12px' }}>
              Scan the QR code to begin your{' '}
              <a
                href='https://play.google.com/store/apps/details?id=com.iamkennis.rotapadapp'
                className='alert-link'
                target='_blank'
              >
                RotaPad Mobile App
              </a>{' '}
              setup and access your account.
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
