// CustomerApprovalContainer

import React, { memo, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import styled from '@emotion/styled';
import { ToolTip } from '../../../common';
import TimeEntry from '../TimeEntry';
import { FiAlertOctagon } from 'react-icons/fi';
import CustomerApproval from '../CustomerApproval';

const WarningStyledDiv = styled.span`
  background: #f6000080;
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
  color: #fcfcfc;
  border-radius: 5px;
  padding: 2px 3px 2px 3px;
  margin-left: 4px;
  cursor: pointer;
  // font-size: 11px;
  // letter-spacing: 0.04rem;
  cursor: ${({ active }) =>
    active !== undefined && active ? 'pointer' : 'default'};
  &:hover {
    background: #000000a1;
    color: #e87a7a;
  }
`;

const CustomerApprovalContainer = ({
  isRotaPadUserAdmin,
  attendee,
  rotaPadUser,
  start,
  uid,
  title,
  handleAddAttendeeHours,
  customer,
  visible,
  setVisible,
}) => {
  // const [visible, setVisible] = useState(false);

  return (
    <div className='text-truncate me-1'>
      <ToolTip
        placement='top'
        overlay={
          <span
            style={{
              fontSize: 10,
            }}
          >
            Customer approval needed!
          </span>
        }
      >
        <WarningStyledDiv
          {...(!isRotaPadUserAdmin && attendee.email === rotaPadUser.email
            ? { onClick: () => setVisible(true) }
            : {})}
          active={!isRotaPadUserAdmin && attendee.email === rotaPadUser.email}
          // onClick={() => setVisible(true)}
        >
          {/* <FiAlertOctagon /> */}
          SEND APPROVAL
        </WarningStyledDiv>
      </ToolTip>
      <CustomerApproval
        hours={attendee?.timeEntry}
        sleepEntry={attendee?.sleepEntry}
        startTimeEntry={attendee?.startTimeEntry}
        endTimeEntry={attendee?.endTimeEntry}
        visible={visible}
        setVisible={setVisible}
        start={start}
        uid={uid}
        title={title}
        handleAddAttendeeHours={handleAddAttendeeHours}
        customer={customer}
      />
    </div>
  );
};

export default memo(CustomerApprovalContainer);
