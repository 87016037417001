import styled from '@emotion/styled';
import SimpleDropDown from 'components/common/SimpleDropDown';
import {
  convertObjectToArray,
  formatCurrency,
  getUnitName,
} from 'components/common/utils';
import { useApiService } from 'components/context';
import _ from 'lodash';
import { useMemo, useState } from 'react';

const StyledInsightDiv = styled.div`
  background: #e3e3e3;
  padding: 12px;
`;

const StyledInsightDivItem = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 4px;
`;

const StyledText = styled.div`
  font-size: 1.75rem;
`;

const insightsData = [
  {
    title: 'Total Shifts',
    text: '200',
    subText: '-',
  },
  {
    title: 'Total Hours',
    text: '200',
    subText: '-',
  },
  {
    title: 'Staff Count',
    text: '200',
    subText: '-',
  },
  {
    title: 'Customer Count',
    text: '200',
    subText: '-',
  },
  {
    title: 'Total Invoices',
    text: '200',
    subText: '-',
  },
  {
    title: 'Total Timesheets',
    text: '200',
    subText: '-',
  },
];

const AccountInsights = () => {
  const { rotaPadShiftsTopic, rotaPadCustomersTopic } = useApiService();
  const [selected, setSelected] = useState(null);
  const getOptions = useMemo(() => {
    // Convert object keys to the desired array format
    const result = [];

    for (const year in rotaPadShiftsTopic) {
      result.push({
        name: year,
        type: 'header',
      });
      const months = Object.keys(rotaPadShiftsTopic[year]).map((month) => ({
        name: `${month} ${year}`,
        disabled: false,
        type: 'item',
      }));
      result.push(...months);
    }

    return result;
  }, [rotaPadShiftsTopic]);

  const getInitialValue = useMemo(() => {
    // Find the first array item with type: 'item'
    const firstItemWithTypeItem = getOptions.find(
      (item) => item.type === 'item'
    );

    return firstItemWithTypeItem ? firstItemWithTypeItem.name : null;
  }, [getOptions]);

  const getInsights = useMemo(() => {
    // Find the first array item with type: 'item'
    if (selected) {
      // console.log('selected ===== ', selected);
      const [month, year] = selected.split(' ');
      const shiftsData = rotaPadShiftsTopic[`${year}`][`${month}`];

      const shiftsDataArray = convertObjectToArray({ item: shiftsData });
      // console.log(
      //   'shiftsData ==== ',
      //   convertObjectToArray({ item: shiftsData })
      // );

      const totalHours = shiftsDataArray.reduce((acc, currentValue) => {
        if (currentValue.attendees && Array.isArray(currentValue.attendees)) {
          currentValue.attendees.forEach((attendee) => {
            if (attendee.timeEntry) {
              acc += attendee.timeEntry;
            }
          });
        }
        return acc;
      }, 0);

      const uniqueStaffCount = shiftsDataArray.reduce((count, item) => {
        if (item.attendees && Array.isArray(item.attendees)) {
          item.attendees.forEach((attendee) => {
            if (attendee && attendee.email) {
              count.add(attendee.email); // Assuming email uniquely identifies an attendee
            }
          });
        }
        return count;
      }, new Set()).size;

      const uniqueCustomersCount = shiftsDataArray.reduce((count, item) => {
        count.add(item.customer);
        return count;
      }, new Set()).size;

      const totalTimesheets = shiftsDataArray.reduce((total, item) => {
        const getCustomerSleepRate = getUnitName({
          unitId: item.customer,
          customers: convertObjectToArray({ item: rotaPadCustomersTopic }),
        }).customerSleepRate;

        if (item.attendees && Array.isArray(item.attendees)) {
          item.attendees.forEach(
            ({ timeEntry = 0, payRate = 0, sleepEntry = 0 }) => {
              if (getCustomerSleepRate) {
                total +=
                  payRate * timeEntry + sleepEntry * getCustomerSleepRate;
              } else {
                total += payRate * timeEntry + sleepEntry * 0;
              }
            }
          );
        }

        return total;
      }, 0);

      const totalInvoices = shiftsDataArray.reduce((total, item) => {
        const getCustomerRates = getUnitName({
          unitId: item.customer,
          customers: convertObjectToArray({ item: rotaPadCustomersTopic }),
        });

        if (item.attendees && Array.isArray(item.attendees)) {
          item.attendees.forEach(({ timeEntry = 0, sleepEntry = 0 }) => {
            if (
              getCustomerRates &&
              getCustomerRates.customerPayRate &&
              getCustomerRates.customerSleepRate
            ) {
              total +=
                getCustomerRates.customerPayRate * timeEntry +
                sleepEntry * getCustomerRates.customerSleepRate;
            } else {
              total += 0 * timeEntry + sleepEntry * 0;
            }
          });
        }

        return total;
      }, 0);

      return [
        {
          title: 'Total Shifts',
          text: `${shiftsDataArray.length}`,
          subText: '-',
        },
        {
          title: 'Total Hours',
          text: `${totalHours.toFixed(2)}`,
          subText: '-',
        },
        {
          title: 'Staff Count',
          text: `${uniqueStaffCount}`,
          subText: '-',
        },
        {
          title: 'Customer Count',
          text: `${uniqueCustomersCount}`,
          subText: '-',
        },
        {
          title: 'Total Invoices',
          text: `${formatCurrency(totalInvoices)}`,
          subText: '-',
        },
        {
          title: 'Total Timesheets',
          text: `${formatCurrency(totalTimesheets)}`,
          subText: '-',
        },
      ];
    } else {
      return [];
    }
  }, [selected, rotaPadShiftsTopic, rotaPadCustomersTopic]);
  return (
    <>
      <h1 style={{ fontSize: '18px' }}>Account Insights</h1>
      <div className='row'>
        <div className='col-12 mt-0'>
          <StyledInsightDiv className='d-flex flex-row mb-3 align-items-center'>
            <div style={{ width: '210px' }}>
              <SimpleDropDown
                title='Select a timeframe'
                handleOnChange={setSelected}
                initialTextValue={getInitialValue}
                options={getOptions}
              />
            </div>
          </StyledInsightDiv>
        </div>
      </div>

      <div className='container-fluid m-0 p-0'>
        <div className='row'>
          {getInsights.map(({ title, text, subText }) => {
            return (
              <div
                key={title}
                className='col-6 col-sm-6 col-md-6 col-lg-2'
              >
                <StyledInsightDivItem className='d-flex flex-column mb-3 border p-3'>
                  <div>{title}</div>
                  <StyledText>{text}</StyledText>
                  <StyledText>{subText}</StyledText>
                </StyledInsightDivItem>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AccountInsights;
