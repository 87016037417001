import styled from '@emotion/styled';
import _ from 'lodash';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Pill, ToolTip } from '../../common';
import { BADGE_STYLE, TOAST_TYPE, USER_TYPES } from '../../common/constants';
import { useToast } from 'components/common/hooks/useToast';
import { useApiService } from 'components/context';
import { convertObjectToArray } from 'components/common/utils';
import {
  StyledSearchInput,
  StyledSearchTermDeleteBtn,
} from 'components/common/PageTitleBar';
import { classNames } from 'primereact/utils';
import { MdOutlineCancel } from 'react-icons/md';
import { useUnmount } from 'react-use';

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
  color: ${(props) => (props.disabled ? '#6c757da6' : 'inherit')};
`;
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
`;

const TeamAvailability = ({
  input: { onChange, value },
  users,
  endValue,
  startValue,
  leaveRequests,
  rotaPadTeam,
  visibleLeft,
}) => {
  // console.log("TeamAvailability rotaPadTeam ====== ", rotaPadTeam);
  const { triggerToast } = useToast();
  const { rotaPadUserGroupsTopic } = useApiService();
  const [selectedGroups, setSelectedGroups] = useState({});
  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    if (rotaPadUserGroupsTopic) {
      const arrayToObject = convertObjectToArray({
        item: rotaPadUserGroupsTopic,
      }).reduce((obj, item) => {
        obj[item.name] = true;
        return obj;
      }, {});

      setSelectedGroups(arrayToObject);
    }
  }, [rotaPadUserGroupsTopic, setSelectedGroups]);

  useEffect(() => {
    if (!visibleLeft) {
      const arrayToObject = convertObjectToArray({
        item: rotaPadUserGroupsTopic,
      }).reduce((obj, item) => {
        obj[item.name] = true;
        return obj;
      }, {});

      setSelectedGroups(arrayToObject);
      setSearchTerm(null);
    }
  }, [visibleLeft, rotaPadUserGroupsTopic]);

  const getLeaveRequestsForDates = useMemo(() => {
    return (
      leaveRequests &&
      leaveRequests.filter((obj) => {
        const startOfMonth = moment(obj.startDate);
        const endOfMonth = moment(obj.endDate);
        var startDate = moment(startValue);
        var endDate = moment(endValue);
        return (
          startDate.isBetween(startOfMonth, endOfMonth, 'days', '[]') ||
          endDate.isBetween(startOfMonth, endOfMonth, 'days', '[]')
        );
      })
    );
  }, [endValue, leaveRequests, startValue]);

  const isDisabled = (attendeeId, getLeaveRequests) => {
    return (
      getLeaveRequests &&
      getLeaveRequests.filter((obj) => obj.user.userId === attendeeId).length >
        0
    );
  };

  const onAssigneeChange = useCallback(
    (e) => {
      if (value && value.length === 1 && e.checked) {
        triggerToast({
          message: 'Shifts can only be assigned to one team member!',
          type: TOAST_TYPE.warning,
        });

        return;
      }

      let _selectedCategories = [...value];

      if (e.checked) _selectedCategories.push(e.value);
      else
        _selectedCategories = _selectedCategories.filter(
          (category) => category.email !== e.value.email
        );

      onChange(_selectedCategories);
    },
    [onChange, value, triggerToast]
  );

  const filteredTeamMembers = useMemo(() => {
    // console.log(
    //   'convertObjectToArray({ item: rotaPadTeam }) ==== ',
    //   convertObjectToArray({ item: rotaPadTeam })
    // );
    return searchTerm === '' || searchTerm === null
      ? convertObjectToArray({ item: rotaPadTeam }).filter(
          (obj) => selectedGroups[obj.group]
        )
      : convertObjectToArray({ item: rotaPadTeam })
          .filter((obj) => selectedGroups[obj.group])
          .filter((person) => {
            // console.log('person ==== ', person.firstname);
            // return true;
            return (
              person.firstname
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              person.lastname
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              person.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
          });
  }, [rotaPadTeam, selectedGroups, searchTerm]);

  if (!_.isObject(rotaPadTeam)) return;

  const AbsenceBadgeContainer = ({ attendee, leaveRequests }) => {
    return (
      <>
        {getLeaveRequestsForDates &&
          getLeaveRequestsForDates
            .filter((obj) => obj.user.userId === attendee._id)
            .map((leaveRequest) => {
              return (
                <Pill
                  content={leaveRequest.leavePolicy}
                  bgStyle={{ ...BADGE_STYLE[leaveRequest.leavePolicy] }}
                  toolTipMessage={`From ${moment(leaveRequest.startDate).format(
                    'MMMM Do YYYY'
                  )} to ${moment(leaveRequest.endDate).format('MMMM Do YYYY')}`}
                />
              );
            })}
      </>
    );
  };

  // console.log('selectedGroups ====== ', selectedGroups);
  return (
    <div className='my-4'>
      <StyledH5 className='mt-3 mb-2'>
        <span className='me-1'>Team Availability</span>
        <Pill
          content={filteredTeamMembers.length}
          bgStyle={{ ...BADGE_STYLE.Active, marginTop: '-4px' }}
          // toolTipMessage={filteredTeamMembers.length}
        />
      </StyledH5>

      {rotaPadUserGroupsTopic && (
        <div className='mb-3'>
          {convertObjectToArray({ item: rotaPadUserGroupsTopic }).map(
            ({ uid, name, handle }) => {
              return (
                <Pill
                  key={uid}
                  content={name}
                  bgStyle={{
                    ...(selectedGroups[name]
                      ? BADGE_STYLE.Group
                      : BADGE_STYLE.Inactive),
                    cursor: 'pointer',
                  }}
                  // toolTipMessage={handle}
                  onClick={() => {
                    setSelectedGroups((prev) => {
                      return {
                        ...prev,
                        [name]: selectedGroups[name] ? false : true,
                      };
                    });
                  }}
                />
              );
            }
          )}
        </div>
      )}

      <div className='field me-2 mb-3 position-relative'>
        <div className='block'>
          <StyledSearchInput
            // id='firstname'
            placeholder='Search'
            value={searchTerm || ''}
            onChange={(x) => {
              setSearchTerm(x.target.value);
            }}
            className={classNames({
              'p-error': true,
            })}
          />
        </div>
        {searchTerm && (
          <StyledSearchTermDeleteBtn
            className='position-absolute top-0 end-0 pe-2'
            onClick={() => {
              setSearchTerm(null);
            }}
          >
            <MdOutlineCancel />
          </StyledSearchTermDeleteBtn>
        )}
      </div>
      <div className='row'>
        {filteredTeamMembers.map((attendee) => {
          const { uid, firstname, lastname, email, role, group } = attendee;
          // console.log('uid ==== ', uid);
          return (
            <div
              key={uid}
              className='col-12 mb-1'
            >
              <div className='field'>
                <div className='block'>
                  <Checkbox
                    inputId={uid}
                    name={firstname + ' ' + lastname}
                    value={attendee}
                    checked={(value || []).some(
                      (item) => item?.email === email
                    )}
                    disabled={role && role === USER_TYPES.Admin}
                    onChange={onAssigneeChange}
                  />
                  <StyledLabel
                    htmlFor={firstname + ' ' + lastname}
                    className={`ms-2 me-2 `}
                    disabled={role && role === USER_TYPES.Admin}
                  >
                    {firstname + ' ' + lastname}
                  </StyledLabel>

                  <Pill
                    content={group}
                    bgStyle={{ ...BADGE_STYLE.Group }}
                    toolTipMessage={group}
                  />

                  {role &&
                    (role === USER_TYPES.Admin ||
                      role === USER_TYPES.Support) && (
                      <ToolTip
                        placement='top'
                        overlayClassName='tooltipCustom'
                        overlay={
                          <span
                            style={{
                              fontSize: 10,
                            }}
                          >
                            Admin user
                          </span>
                        }
                      >
                        <button
                          type='button'
                          style={{
                            background: '#be20a5',
                            color: '#fff',
                            fontSize: '10px',
                            height: '20px',
                            border: 'none',
                            borderRadius: '10px',
                            lineHeight: '19px',
                            cursor: 'auto',
                            marginRight: '3px',
                          }}
                        >
                          {role === USER_TYPES.Support ? 'Swiss Support' : role}
                        </button>
                      </ToolTip>
                    )}
                  <AbsenceBadgeContainer
                    attendee={attendee}
                    leaveRequests={getLeaveRequestsForDates}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TeamAvailability;
